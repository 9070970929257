<template>
  <!-- 快速购买页面 -->
  <v-container fluid>
      <v-card class="pa-6">
          <v-card-title class="mb-4">{{$t('lang.quickBuy.buyGuide')}}</v-card-title>
          <v-card-subtitle class="mb-4 primary--text" v-if="$root.certType == 'STUDENT'">{{$t('lang.quickBuy.studentDiscount')}}</v-card-subtitle>
          <v-card-text>
            <!-- 站的数量 -->
              <v-row  class="d-flex align-center">
                <v-col cols="1" class="font-weight-bold">{{$t('lang.quickBuy.stationNum')}}</v-col>
                <v-col cols="11">
                    <vue-numeric-input v-model="stationNums" :min="minStationNum" :max="maxStationNum" :step="1" align="center" class="numeric-input" :disabled="renewalAble"  @input="changeStationNum()"></vue-numeric-input>
                </v-col>
              </v-row>
               <!-- 数据返回格式 -->
              <v-row class="d-flex align-center">
                  <v-col cols="1" class="font-weight-bold">{{$t('lang.quickBuy.dataFormat')}}</v-col>
                  <v-col cols="auto">
                    <v-select v-model="selectedDataFormat" :items="dataFormat" :disabled="renewalAble"></v-select>
                  </v-col>
              </v-row>
              <!-- 数据存储时长 -->
              <v-row class="d-flex align-center">
                <v-col cols="1" class="font-weight-bold">{{$t('lang.quickBuy.dataHoldTime')}}</v-col>
                <v-col cols="auto">
                    <v-btn-toggle v-model="dataHoldTimeIndex" color="primary" @change="changeDataHoldTimeIndex()">
                        <v-btn v-for="(time, i) in basicTimeOptions" :key="i" :disabled="renewalAble">
                          {{time.text}}
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
                <v-col cols="auto">
                    <v-select v-model="moreDataHoldTime" :label="$t('lang.quickBuy.moreTime')" :disabled="renewalAble" :items="moreTimeOptions" item-text="text" item-value="dayValue" return-object @change="changeMoreDataHoldTime()"> </v-select>
                </v-col>
              </v-row>     
              <!-- 服务时长 -->
              <v-row class="d-flex align-center">
                <v-col cols="1" class="font-weight-bold">{{$t('lang.quickBuy.serviceTime')}}</v-col>
                <v-col cols="auto">
                    <v-btn-toggle v-model="serviceTimeIndex" color="primary" @change="changeServiceTimeIndex()">
                        <v-btn v-for="(time, i) in basicTimeOptions" :key="i">
                          {{time.text}}
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
                <v-col cols="auto">
                    <v-select v-model="moreServiceTime" :label="$t('lang.quickBuy.moreTime')" :items="moreTimeOptions" item-text="text" item-value="monthValue" return-object @change="changeMoreServiceTime()"> </v-select>
                </v-col>
              </v-row>
          </v-card-text>
          <!-- 费用及确认 -->
          <v-card-actions class="">
              <v-spacer></v-spacer>
              <div class="mr-6">
                  <div v-if="totalMoney" class="d-flex align-center">
                    <span class="mr-2">{{$t('lang.quickBuy.cost')}}:</span>
                    <span class="orange--text text-h6">￥{{totalMoney}}</span>
                  </div>
                  <div v-else class="orange--text text-h6">{{$t('lang.quickBuy.calculatingPrice')}}</div>
              </div>
              <v-btn class="orange white--text" @click="confirmBuy()">{{renewalAble ?  $t('lang.quickBuy.renewalBtn') : $t('lang.quickBuy.buyBtn')}}</v-btn>
          </v-card-actions>
      </v-card>
      <!-- 购买成功/失败提示框 -->
      <v-dialog v-model="showBuyDialog" max-width="500" persistent>
          <v-card>
              <v-card-title>
                  <div v-if="buySuccess"><v-icon color="success">mdi-check-circle</v-icon>{{$t('lang.quickBuy.tips.buySuccess')}}</div>
                  <div v-else><v-icon color="error">mdi-close-circle</v-icon>{{$t('lang.quickBuy.tips.buyFail')}}</div>
              </v-card-title>
              <div v-if="buySuccess">
                <v-card-subtitle class="mt-2">{{$t('lang.quickBuy.transfer.hint')}}</v-card-subtitle>
                <v-card-text>
                  <div><span class="font-weight-bold">{{$t('lang.quickBuy.transfer.bank')}}: </span><span>{{$t('lang.quickBuy.transfer.bankName')}}</span></div>
                  <div><span class="font-weight-bold">{{$t('lang.quickBuy.transfer.account')}}: </span><span>6214****3420</span></div>
                  <div><span class="font-weight-bold">{{$t('lang.quickBuy.transfer.companyName')}}: </span><span>{{$t('lang.quickBuy.transfer.wuzhou')}}</span></div>
                  <div><span class="font-weight-bold">{{$t('lang.quickBuy.transfer.phone')}}: </span><span>0631-3683331</span></div>
                </v-card-text>
              </div>
              <div v-else>
                <v-card-subtitle class="">{{$t('lang.quickBuy.buyFailTip.reason')}}:</v-card-subtitle>
                <v-card-text>
                  <div>1. {{$t('lang.quickBuy.buyFailTip.unpaid')}}</div>
                  <div>2. {{$t('lang.quickBuy.buyFailTip.netError')}}</div>
                </v-card-text>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="primary" @click="showBuyDialog = false">{{$t('lang.package.confirmBtn')}}</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </v-container>
  
</template>
<script>
import VueNumericInput from 'vue-numeric-input'
import axios from 'axios'
import commonCfg from "../../config/common";
let apiUrl = commonCfg.commonUrl

let orderParam = null
export default {
    data() {
        return {
          stationNums: 1,
          maxStationNum: 20,
          minStationNum: 1,
          basicTimeOptions: [
            { text: this.$t('lang.quickBuy.oneMonth'), dayValue: 30, monthValue: 1 },
            { text: this.$t('lang.quickBuy.oneYear'), dayValue: 365, monthValue: 12 },
            { text: this.$t('lang.quickBuy.threeYears'), dayValue: 1095, monthValue: 36 },
            { text: this.$t('lang.quickBuy.fiveYears'), dayValue: 1825, monthValue: 60 },
          ],
          moreTimeOptions: [
            { text: this.$t('lang.quickBuy.fourYears'), dayValue: 1460, monthValue: 48 },
            { text: this.$t('lang.quickBuy.twoYears'), dayValue: 730, monthValue: 24 },
            { text: this.$t('lang.quickBuy.halfYear'), dayValue: 180, monthValue: 6 },
            { text: this.$t('lang.quickBuy.threeMonths'), dayValue: 90, monthValue: 3 },
            { text: this.$t('lang.quickBuy.twoMonths'), dayValue: 60, monthValue: 2 },
            // { text: this.$t('lang.quickBuy.fifteenDays'), dayValue: 15, monthValue: 0.5 },
          ],
          serviceTimeIndex: 0,
          moreServiceTime: '',
          dataHoldTimeIndex: 0,
          moreDataHoldTime: '',
          dataFormat: ['JSON'],
          selectedDataFormat: 'JSON',
          totalMoney: '',
          showBuyDialog: false,
          renewalAble: false,
          orderInfo: '',
          buySuccess: false
        }
    },
    components: {
      VueNumericInput
    },
    async created() {     
        await this.getOrderStatus()
        if(!this.renewalAble) this.calculatePrice()     
    },
    methods: {
        //改变服务时长选项计算价格
        changeServiceTimeIndex() {
            console.log(this.serviceTimeIndex)
            if(this.serviceTimeIndex || this.serviceTimeIndex >= 0) {
                if(this.moreServiceTime) this.moreServiceTime = ''
            }
            this.calculatePrice()
        },
        //改变服务时长选项计算价格
        changeMoreServiceTime() {
            console.log(this.moreServiceTime)
            if(this.serviceTimeIndex || this.serviceTimeIndex >= 0) this.serviceTimeIndex = ''
            this.calculatePrice()
        },
        //改变数据存储时长选项计算价格
        changeDataHoldTimeIndex() {
            console.log(this.dataHoldTimeIndex)
            if(this.dataHoldTimeIndex || this.dataHoldTimeIndex >= 0) {
                if(this.moreDataHoldTime) this.moreDataHoldTime = ''
            }
            this.calculatePrice()
        },
        //改变数据存储时长选项计算价格
        changeMoreDataHoldTime() {
            console.log(this.moreDataHoldTime)
            if(this.dataHoldTimeIndex || this.dataHoldTimeIndex >= 0) this.dataHoldTimeIndex = ''
            this.calculatePrice()
        },
        //改变站的数量计算价格
        changeStationNum() {
          if(!this.stationNums) return
          if(this.stationNums > this.maxStationNum || this.stationNums < this.minStationNum) return
           console.log(this.stationNums)
           this.calculatePrice()
        },
        //计算价格
        calculatePrice() {
            //this.totalMoney = ''
            orderParam = {
               stationNum: '',
               dataHoldDays: '',
               serviceMonths: '',
               dataFormat: ''
            }
            if(this.renewalAble) {//续费
              orderParam.stationNum =  this.orderInfo.stationNum,
              orderParam.dataHoldDays = this.orderInfo.dataHoldDays,
              orderParam.dataFormat =  this.orderInfo.dataFormat
            }else {
              orderParam.stationNum = this.stationNums
              orderParam.dataFormat = this.selectedDataFormat
              if(this.moreDataHoldTime) {
                orderParam.dataHoldDays = this.moreDataHoldTime.dayValue
              }else {
                orderParam.dataHoldDays = this.basicTimeOptions[this.dataHoldTimeIndex].dayValue
              }
            }
            if(this.moreServiceTime) {
                orderParam.serviceMonths = this.moreServiceTime.monthValue
            }else {
                orderParam.serviceMonths = this.basicTimeOptions[this.serviceTimeIndex].monthValue
            }
            console.log(orderParam)
            this.sendCalculatePriceReq(orderParam)
        },
        //发送计算价格的请求
        sendCalculatePriceReq(param) {
          axios.post(`${apiUrl}order/calculatePrice`, param).then(res => {
              console.log(res)
              if(res.status == 200) {
                this.totalMoney = res.data / 100
                return
              }
              this.totalMoney = ''
            }).catch(error => {
               console.log(error)
               this.totalMoney = ''
            })
        },
        //获取订单的状态
        async getOrderStatus() {
          await axios.get(`${apiUrl}order/status`).then(res => {
              console.log(res)
              if(res.status != 200) {
                this.renewalAble = false
                return
              }
              if(!res.data) return //res.data是null 代表有未支付的订单或没有订单
              if(new Date().getTime() <= res.data.endTime) {//存在未过期的订单 只允许续费
                  this.renewalAble = true
                  this.orderInfo = res.data//订单状态
                  orderParam = {
                    stationNum: this.orderInfo.stationNum,
                    dataHoldDays: this.orderInfo.dataHoldDays,
                    serviceMonths: '',
                    dataFormat: this.orderInfo.dataFormat
                  }
                  if(this.moreServiceTime) {
                    orderParam.serviceMonths = this.moreServiceTime.monthValue
                  }else {
                    orderParam.serviceMonths = this.basicTimeOptions[this.serviceTimeIndex].monthValue
                  }
                  this.sendCalculatePriceReq(orderParam)
                  return 
              }
              this.renewalAble = false 
            }).catch(error => {
               console.log(error)
               this.renewalAble = false
               
            })
        },
        //确认购买
        confirmBuy() {
           this.$dialog.confirm({
              title: this.$t('lang.quickBuy.confirmBuy'),
              text: this.$t('lang.quickBuy.buyTips'),
              actions: [
                {
                  text: this.$t('lang.station.cancelBtn'),
                  key: "false",
                },
                {
                  text: this.$t('lang.package.confirmBtn'),
                  color: "blue",
                  key: true,
                },
              ],
            })
            .then((type) => { 
                if(!type) return
                //order create
                console.log(orderParam)
                this.createOrder(orderParam)
                
              }
          )
        },
        //发送创建订单的请求
        createOrder(param) {
          axios.post(`${apiUrl}order/create`, param).then(res => {
              console.log(res)
              this.showBuyDialog = true
              if(res.status == 200) {
                this.buySuccess = true
                this.getOrderStatus()
                return
              }
              this.buySuccess = false
            }).catch(error => {
              console.log(error) //有未支付的订单
              this.showBuyDialog = true
              this.buySuccess = false
            })
        }
    }
}
</script>
<style>
.numeric-input {
    height: 2.2rem;
}
.numeric-input .btn-icon {
    opacity: 0.5
}
</style>